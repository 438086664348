function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { actions } from "../../BasicReducer";
import { getDocument } from "./documentActions";
import referencesAPI from "../referencesAPI";
import relationTypesAPI from "../../RelationTypes/RelationTypesAPI";
import * as relationships from "../../Relationships/utils/routeUtils";

import { setReferences } from "./referencesActions";
import entitiesAPI from "../../Entities/EntitiesAPI";

export function setViewerState(state) {
  return (dispatch) => {
    const { documentViewer } = state;
    dispatch(actions.set('relationTypes', state.relationTypes));
    dispatch(actions.set('viewer/doc', documentViewer.doc));
    dispatch(actions.set('viewer/relationTypes', documentViewer.relationTypes));
    dispatch(actions.set('viewer/rawText', documentViewer.rawText));
    dispatch(setReferences(documentViewer.references));
    dispatch(relationships.setReduxState(state));
  };
}

export async function requestViewerState(requestParams, globalResources) {
  const { sharedId, raw, page } = requestParams.data;
  const defaultLanguage = globalResources.settings.collection.
  get('languages').
  find((l) => l.get('default'));

  const [doc, relationTypes, [connectionsGroups, searchResults, sort]] = await Promise.all([
  getDocument(
  requestParams.set({ sharedId }),
  defaultLanguage ? defaultLanguage.get('key') : 'en',
  requestParams.data.file),

  relationTypesAPI.get(requestParams.onlyHeaders()),
  relationships.requestState(requestParams.set({ sharedId }), globalResources.templates)]);


  const { defaultDoc } = doc;
  const rawText = raw ?
  await entitiesAPI.getRawPage(requestParams.set({ _id: defaultDoc._id, page })) :
  '';

  const references = await referencesAPI.get(
  requestParams.set({ sharedId, file: doc.defaultDoc._id, onlyTextReferences: true }));


  return [
  setViewerState({
    documentViewer: {
      doc: _objectSpread(_objectSpread({},
      doc), {}, {
        relations: references }),

      references,
      relationTypes,
      rawText
    },
    relationships: {
      list: {
        sharedId: doc.sharedId,
        entity: doc,
        connectionsGroups,
        searchResults,
        sort,
        filters: {},
        view: 'graph'
      }
    },
    relationTypes
  })];

}