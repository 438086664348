function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { connect } from 'react-redux';

import { RequestParams } from "../../../utils/RequestParams";
import api from "../../../Search/SearchAPI";
import { t } from "../../../I18N";

import CejilChart from "./CejilChart";
import parsingUtils from "../utils/parsingUtils";

export const judgesCommisionersTemplate = '58b2f3a35d59f31e1345b4b6';
export const countryKey = 'pa_s';
export const male = 'dedcc624-0e11-4d4e-90d5-d1c0ea4c7a18';
export const female = 'f2457229-e142-4b74-b595-2ac2f9b5f64e';
export const sexTranslationsContext = '58b2f3a35d59f31e1345b52d';

function assignFilter(filters, sex) {
  return _objectSpread(_objectSpread({}, filters), {}, { sexo: { values: [sex] } });
}

function conformSearchQuery(filters) {
  return api.search(new RequestParams({ types: [judgesCommisionersTemplate], filters, limit: 0 }));
}

function getData() {
  const filters = {};
  filters[this.props.filterProperty] = { from: -2208988800 };

  const maleFilters = assignFilter(filters, male);
  const femaleFilters = assignFilter(filters, female);

  return Promise.all([filters, maleFilters, femaleFilters].map(conformSearchQuery));
}

function prepareData(countries, setA, setB) {
  return countries.map((_country) => {
    const country = _country;
    const maleResults = parsingUtils.findBucketsByCountry(setA, countryKey, country.key);
    const femaleResults = parsingUtils.findBucketsByCountry(setB, countryKey, country.key);

    country.name = country.label;

    country.setALabel = t(sexTranslationsContext, 'Hombre', null, false);
    country.setAValue = maleResults ? maleResults.filtered.doc_count : 0;

    country.setBLabel = t(sexTranslationsContext, 'Mujer', null, false);
    country.setBValue = femaleResults ? femaleResults.filtered.doc_count : 0;

    return country;
  });
}

export function mapStateToProps({ thesauris }, { filterProperty = 'mandatos_de_la_corte' }) {
  return { thesauris, getData, prepareData, filterProperty };
}

export default connect(mapStateToProps)(CejilChart);