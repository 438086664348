function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import * as types from "./actionTypes";
import referencesAPI from "../referencesAPI";
import { notificationActions } from "../../Notifications";
import { actions } from "../../BasicReducer";
import { RequestParams } from "../../utils/RequestParams";

import { actions as connectionsActions } from "../../Connections";
import { reloadRelationships } from "../../Relationships/actions/actions";
import * as uiActions from "./uiActions";

export function setReferences(references) {
  return {
    type: types.SET_REFERENCES,
    references
  };
}

export function loadReferences(sharedId) {
  return (dispatch) =>
  referencesAPI.get(new RequestParams({ sharedId })).then((references) => {
    dispatch(setReferences(references));
  });
}

export function addReference(references, delayActivation) {
  return (dispatch, getState) => {
    const tab = 'references';

    dispatch({ type: types.ADD_REFERENCE, reference: references.saves[1] });
    dispatch({ type: types.ADD_REFERENCE, reference: references.saves[0] });

    dispatch(actions.unset('viewer/targetDoc'));
    dispatch(actions.unset('viewer/targetDocHTML'));
    dispatch(actions.unset('viewer/targetDocReferences'));
    dispatch(reloadRelationships(getState().relationships.list.sharedId));

    dispatch(uiActions.activateReference(references.saves[0], tab, delayActivation));
  };
}

export function saveTargetRangedReference(connection, targetRange, targetFile, onCreate) {
  return (dispatch, getState) => {
    if (targetRange.text) {
      dispatch(actions.unset('viewer/targetDocReferences'));
      return connectionsActions.saveConnection(_objectSpread(_objectSpread({},
      connection), {}, { targetRange, targetFile }),
      onCreate)(
      dispatch, getState);
    }
    return undefined;
  };
}

export function deleteReference(reference) {
  const { _id } = reference.associatedRelationship;
  return (dispatch, getState) =>
  referencesAPI.delete(new RequestParams({ _id })).then(() => {
    dispatch(reloadRelationships(getState().relationships.list.sharedId));
    dispatch({ type: types.REMOVE_REFERENCE, reference });
    dispatch(notificationActions.notify('Connection deleted', 'success'));
  });
}