import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveEntity, resetForm } from "../actions/actions";
import { actions, MetadataForm } from "../../Metadata";

function mapStateToProps(state) {
  return {
    model: 'entityView.entityForm',
    templateId: state.entityView.entityForm.template,
    templates: state.templates,
    thesauris: state.thesauris
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
  {
    changeTemplate: actions.changeTemplate,
    onSubmit: saveEntity,
    componentWillUnmount: resetForm
  },
  dispatch);

}

export default connect(mapStateToProps, mapDispatchToProps)(MetadataForm);