function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { t } from "../../I18N";

import { propertyTypes } from "../../../shared/propertyTypes";

















const getCurrentSortOption = (sortOptions, sortOption) => {
  if (!sortOption || sortOption === 'creationDate') {
    const currentOption = sortOptions.find((option) => option.value === 'creationDate');
    return currentOption === null || currentOption === void 0 ? void 0 : currentOption.label;
  }
  const currentOption = sortOptions.find((option) => option.value === sortOption);
  return currentOption === null || currentOption === void 0 ? void 0 : currentOption.label;
};

const getPropertySortType = (selected) =>
selected.type === 'text' || selected.type === 'select' ? 'string' : 'number';

const isSortableType = (type) => {
  switch (type) {
    case propertyTypes.text:
    case propertyTypes.date:
    case propertyTypes.numeric:
    case propertyTypes.select:
      return true;
    default:
      return false;}

};

const isSortable = (property) =>
property.filter && (
isSortableType(property.type) || property.inherit && isSortableType(property.inherit.type));

const getSortString = (property) =>
`metadata.${property.name}${property.inherit ? '.inheritedValue' : ''}`;

const getMetadataSorts = (templates) =>
templates.toJS().reduce((sorts, template) => {
  (template.properties || []).forEach((property) => {
    if (isSortable(property) && !sorts.find((s) => s.name === property.name)) {
      sorts.push({
        label: property.label,
        name: property.name,
        value: getSortString(property),
        type: property.type,
        context: template._id
      });
    }
  });
  return sorts;
}, []);

const getCommonSorts = (search) => [
...[
{ label: 'Title', value: 'title', type: 'text', context: 'System' },
{ label: 'Date added', value: 'creationDate', type: 'number', context: 'System' },
{ label: 'Date modified', value: 'editDate', type: 'number', context: 'System' }],

...(search.searchTerm ?
[
{
  label: 'Search relevance',
  value: '_score',
  type: 'number',
  context: 'System'
}] :

[])];


const filterTemplates = (
templates,
selectedTemplates) =>

templates.filter((i) => i !== undefined && selectedTemplates.includes(i.get('_id')));



const getSortOptions = (
search,
templates) =>

[...getCommonSorts(search), ...getMetadataSorts(templates)].map((option) => _objectSpread(_objectSpread({},
option), {}, {
  label: t(option.context, option.label, undefined, false) }));




export { getPropertySortType, getCurrentSortOption, filterTemplates, getSortOptions };