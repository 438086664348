import { actions } from "../../BasicReducer";
import TemplatesAPI from "../../Templates/TemplatesAPI";
import api from "../ThesauriAPI";
import { RequestParams } from "../../utils/RequestParams";
import { actions as formActions } from 'react-redux-form';

export function editThesaurus(thesaurus) {
  return (dispatch) => {
    dispatch(formActions.reset('thesauri.data'));
    dispatch(formActions.load('thesauri.data', thesaurus));
  };
}

export function deleteThesaurus(thesaurus) {
  return (dispatch) =>
  api.delete(new RequestParams({ _id: thesaurus._id })).then(() => {
    dispatch(actions.remove('dictionaries', thesaurus));
  });
}

export function checkThesaurusCanBeDeleted(thesaurus) {
  return (dispatch) =>
  TemplatesAPI.countByThesauri(new RequestParams({ _id: thesaurus._id })).then((count) =>
  count ? Promise.reject() : dispatch);

}

export function reloadThesauri() {
  return (dispatch) =>
  api.get().then((response) => {
    dispatch(actions.set('thesauris', response));
  });
}