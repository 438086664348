import superagent from 'superagent';
import { toUrlParams } from "../../../shared/JSONRequest";
import { actions } from "../../BasicReducer";
import { notify } from "../../Notifications/actions/notificationsActions";
import { t } from "../../I18N";




import { processFilters } from "./libraryActions";


export function triggerLocalDownload(content, fileName) {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function clearState(dispatch) {
  dispatch(actions.set('exportSearchResultsProcessing', false));
  dispatch(actions.set('exportSearchResultsContent', ''));
  dispatch(actions.set('exportSearchResultsFileName', ''));
}

export function exportEnd() {
  return (dispatch, getState) => {
    const { exportSearchResultsContent, exportSearchResultsFileName } =
    getState().exportSearchResults;

    triggerLocalDownload(exportSearchResultsContent, exportSearchResultsFileName);

    clearState(dispatch);
  };
}

function extractFileName(contentDisposition) {
  const startIndex = contentDisposition.indexOf('filename="') + 10;
  const endIndex = contentDisposition.length - 1;
  return contentDisposition.substring(startIndex, endIndex);
}

const requestHandler = (params, dispatch, captcha) => {
  let request = superagent.
  get(`/api/export${toUrlParams(params)}`).
  set('Accept', 'text/csv').
  set('X-Requested-With', 'XMLHttpRequest');

  if (captcha) {
    request = request.set('Captcha-text', captcha.text).set('Captcha-id', captcha.id);
  }

  request.
  then((response) => {
    const fileName = extractFileName(response.header['content-disposition']);
    dispatch(actions.set('exportSearchResultsContent', response.text));
    dispatch(actions.set('exportSearchResultsFileName', fileName));
    dispatch(exportEnd());
  }).
  catch((err) => {
    clearState(dispatch);
    if (err.status === 403) {
      dispatch(notify(t('System', 'Invalid captcha'), 'danger'));
    } else {
      dispatch(notify(t('System', 'An error has occurred during data export'), 'danger'));
    }
    return err;
  });
};

export function exportDocuments(storeKey, captcha) {
  return async (dispatch, getState) => {
    const state = getState()[storeKey];
    const { search, filters } = state;
    const exportFilters = filters.toJS();

    const finalSearchParams = processFilters(search, exportFilters, 10000);
    finalSearchParams.searchTerm = state.search.searchTerm;

    if (state.ui.get('selectedDocuments').size) {
      finalSearchParams.ids = state.ui.
      get('selectedDocuments').
      map((entity) => entity.get('sharedId'));
    }

    if (storeKey === 'uploads') finalSearchParams.unpublished = true;

    dispatch(actions.set('exportSearchResultsProcessing', true));

    requestHandler(finalSearchParams, dispatch, captcha);
  };
}