function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { actions } from "../../BasicReducer/reducer";
import { actions as formActions } from 'react-redux-form';
import { RequestParams } from "../../utils/RequestParams";
import api from "../../utils/api";
import { notificationActions } from "../../Notifications";


import { ensure } from "../../../shared/tsUtils";


const tocGenerationActions = {
  reviewToc(fileId) {
    return async (dispatch, getState) => {
      const currentDoc = getState().documentViewer.doc.toJS();
      dispatch(formActions.reset('documentViewer.sidepanel.metadata'));

      const updatedFile = (await api.post('files/tocReviewed', new RequestParams({ fileId }))).json;
      const doc = _objectSpread(_objectSpread({},
      currentDoc), {}, {
        defaultDoc: updatedFile,
        documents: ensure(currentDoc.documents).map((d) => {
          if (d._id === updatedFile._id) {
            return updatedFile;
          }
          return d;
        }) });


      dispatch(notificationActions.notify('Document updated', 'success'));
      dispatch(formActions.reset('documentViewer.sidepanel.metadata'));
      dispatch(actions.set('viewer/doc', doc));
    };
  }
};

export { tocGenerationActions };