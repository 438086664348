function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { actions } from "../../BasicReducer";
import api from "../../Entities/EntitiesAPI";
import entitiesUtil from "../../Entities/utils/filterBaseProperties";
import { wrapEntityMetadata } from "../../Metadata";
import { loadFetchedInReduxForm } from "../../Metadata/actions/actions";
import * as relationships from "../../Relationships/utils/routeUtils";
import { RequestParams } from "../../utils/RequestParams";
import Immutable from 'immutable';




export async function getAndLoadEntity(
requestParams,
templates,
state,
loadConnections)
{
  const [[entity], [connectionsGroups, searchResults, sort, filters]] = await Promise.all([
  api.get(requestParams),
  loadConnections ?
  relationships.requestState(requestParams, state) :
  [[], { rows: [] }, {}, Immutable.fromJS({})]]);


  return [
  actions.set('entityView/entity', entity),
  relationships.setReduxState({
    relationships: {
      list: {
        sharedId: entity.sharedId,
        entity,
        connectionsGroups,
        searchResults,
        sort,
        filters,
        view: 'graph'
      }
    }
  }),
  ...loadFetchedInReduxForm('entityView.entityForm', entity, templates)];

}

export function toggleOneUpFullEdit() {
  return async (dispatch, getState) => {var _state$oneUpReview$st;
    const state = getState();
    const oneUpState = (_state$oneUpReview$st = state.oneUpReview.state) === null || _state$oneUpReview$st === void 0 ? void 0 : _state$oneUpReview$st.toJS();
    if (oneUpState && oneUpState.fullEdit && !state.entityView.entityFormState.$form.pristine) {
      const entity = await api.denormalize(
      new RequestParams(
      wrapEntityMetadata(entitiesUtil.filterBaseProperties(state.entityView.entityForm))));


      dispatch(actions.set('entityView/entity', entity));
    }
    dispatch(
    actions.set('oneUpReview.state', _objectSpread(_objectSpread({},
    oneUpState), {}, {
      fullEdit: oneUpState ? !oneUpState.fullEdit : false })));


  };
}

async function switchToEntity(
dispatch,
index,
state,
oneUpState)
{
  const sharedId =
  index < oneUpState.totalDocs ?
  state.library.documents.get('rows').get(index).get('sharedId') :
  '';

  [
  ...(sharedId ?
  await getAndLoadEntity(
  new RequestParams({ sharedId }, oneUpState.requestHeaders),
  state.templates.toJS(),
  state,
  oneUpState.loadConnections) :

  []),
  actions.set('oneUpReview.state', _objectSpread(_objectSpread({},
  oneUpState), {}, {
    indexInDocs: index }))].

  forEach((action) => {
    dispatch(action);
  });
}

export function switchOneUpEntity(delta, save) {
  return async (dispatch, getState) => {var _state$oneUpReview$st2;
    const state = getState();
    const oneUpState = (_state$oneUpReview$st2 = state.oneUpReview.state) === null || _state$oneUpReview$st2 === void 0 ? void 0 : _state$oneUpReview$st2.toJS();
    if (!oneUpState) {
      return;
    }
    if (save) {
      const entity = wrapEntityMetadata(
      entitiesUtil.filterBaseProperties(state.entityView.entityForm));

      await api.save(new RequestParams(entity, oneUpState.requestHeaders));
    }
    const current = state.entityView.entity.get('sharedId');
    const index = Math.max(
    0,
    Math.min(
    state.library.documents.get('rows').findIndex((e) => e.get('sharedId') === current) + delta,
    oneUpState.totalDocs - 1));


    await switchToEntity(dispatch, index, state, oneUpState);
  };
}

export function toggleOneUpLoadConnections() {
  return async (dispatch, getState) => {var _state$oneUpReview$st3;
    const state = getState();
    const oneUpState = (_state$oneUpReview$st3 = state.oneUpReview.state) === null || _state$oneUpReview$st3 === void 0 ? void 0 : _state$oneUpReview$st3.toJS();
    if (!oneUpState) {
      return;
    }
    dispatch(
    actions.set('oneUpReview.state', _objectSpread(_objectSpread({},
    oneUpState), {}, {
      loadConnections: !oneUpState.loadConnections })));


    await dispatch(switchOneUpEntity(0, false));
  };
}