/** @format */

import { combineReducers } from 'redux';
import createReducer from "./BasicReducer";

import template from "./Templates/reducers/reducer";
import page from "./Pages/reducers/reducer";
import notifications from "./Notifications/reducers/notificationsReducer";

import thesauri from "./Thesauri/reducers/reducer";
import activitylog from "./Activitylog/reducer";
import documentViewer from "./Viewer/reducers/reducer";
import entityView from "./Entities/reducers/reducer";
import contextMenu from "./ContextMenu/reducers/contextMenuReducer";
import connections from "./Connections/reducers/reducer";
import relationships from "./Relationships/reducers/reducer";
import ConnectionsList from "./ConnectionsList/reducers/reducer";
import { reducer as attachments } from "./Attachments";
import semanticSearch from "./SemanticSearch/reducers/reducer";

import library from "./Library/reducers/reducer";
import modals from "./Modals/reducers/modalsReducer";
import progress from "./Uploads/reducers/progressReducer";
import importEntities from "./Uploads/reducers/importReducer";
import user from "./Auth/reducer";
import settings from "./Settings/reducers/reducer";
import metadata from "./Metadata/reducer";
import locale from "./I18N/reducer";
import inlineEdit from "./I18N/inlineEditReducer";
import oneUpReview from "./Review/reducers/reducer";
import exportSearchResults from "./Library/reducers/exportReducer";

import { modelReducer, formReducer } from 'react-redux-form';

export default combineReducers({
  notifications,
  library: library('library'),
  uploads: library('uploads'),
  progress,
  importEntities,
  locale,
  inlineEdit,
  semanticSearch,
  activitylog,
  inlineEditForm: formReducer('inlineEditModel', {}),
  inlineEditModel: modelReducer('inlineEditModel', {}),
  template,
  page,
  thesauri,
  entityView,
  thesauris: createReducer('thesauris', []),
  entityThesauris: createReducer('entityThesauris', {}),
  customUploads: createReducer('customUploads', []),
  dictionaries: createReducer('dictionaries', []),
  relationTypes: createReducer('relationTypes', []),
  relationType: modelReducer('relationType', { name: '' }),
  relationTypeForm: formReducer('relationType', { name: '' }),
  templates: createReducer('templates', []),
  translations: createReducer('translations', []),
  translationsForm: modelReducer('translationsForm', []),
  translationsFormState: formReducer('translationsForm'),
  pages: createReducer('pages', []),
  users: createReducer('users', []),
  documentViewer,
  contextMenu,
  connections,
  connectionsList: ConnectionsList,
  relationships,
  attachments,
  modals,
  user,
  settings,
  metadata,
  oneUpReview,
  exportSearchResults,
  userGroups: createReducer('userGroups', [])
});