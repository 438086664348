import { createSelector } from 'reselect';


export const selectEntity = createSelector(
(state) => state.entityView.entity,
(entity) => entity === null || entity === void 0 ? void 0 : entity.toJS());


export const selectOneUpState = createSelector(
(state) => state.oneUpReview.state,
(state) => state === null || state === void 0 ? void 0 : state.toJS());


export const selectMlThesauri = createSelector(
(state) => state.thesauris,
(thesauri) =>
thesauri.
filter((thes) => !!thes.get('enable_classification')).
map((thes) => {var _get$toString, _get;return (_get$toString = (_get = thes.get('_id')) === null || _get === void 0 ? void 0 : _get.toString()) !== null && _get$toString !== void 0 ? _get$toString : '';}).
toJS());


export const selectIsPristine = createSelector(
(state) => state.entityView.entityFormState.$form.pristine,
(value) => value);