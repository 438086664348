

import { t } from "../../I18N";
import { notificationActions } from "../../Notifications";

import { RequestParams } from "../../utils/RequestParams";
import api from "../../utils/api";
import { acceptEntitySuggestion } from "../SuggestionsAPI";
import EntitiesAPI from "../../Entities/EntitiesAPI";
import scroller from "../../Viewer/utils/Scroller";
import { actions } from "../../BasicReducer";
import { saveConfigurations as saveConfigs } from "../SuggestionsAPI";


const saveConfigurations =
(newSettingsConfigs) => async (dispatch) => {
  const settings = await saveConfigs(new RequestParams(newSettingsConfigs));
  dispatch(actions.set('settings/collection', settings));
  dispatch(notificationActions.notify(t('System', 'Settings updated'), 'success'));
};

const fetchEntity = async (entityId, language) => {
  const entityRequest = new RequestParams({ _id: entityId });
  return EntitiesAPI.get(entityRequest, language);
};

const fetchFile = async (fileId) => {
  const fileRequest = new RequestParams({ _id: fileId });
  return api.get('files', fileRequest);
};

const scrollToPage = async (pageNumber) =>
scroller.to(`.document-viewer div#page-${pageNumber}`, '.document-viewer', {
  duration: 0,
  dividerOffset: 1,
  offset: 50
});

const acceptSuggestion =
(suggestion, allLanguages) =>
async (dispatch) => {
  const params = new RequestParams({
    allLanguages,
    suggestion: {
      _id: suggestion._id,
      sharedId: suggestion.sharedId,
      entityId: suggestion.entityId
    }
  });
  const result = await acceptEntitySuggestion(params);
  if (result.success) {
    dispatch(
    notificationActions.notify(t('System', 'Saved successfully.', null, false), 'success'));

  }
};

export { acceptSuggestion, fetchEntity, fetchFile, scrollToPage, saveConfigurations };