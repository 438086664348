function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelection, unsetSelection } from "../actions/selectionActions";
import {
resetReferenceCreation,
highlightReference,
activateReference,
scrollToActive,
deactivateReference } from "../actions/uiActions";

import Document from "./Document";
import { createSelector } from 'reselect';
import { selectDoc, selectReferences } from "../selectors";

const selectSourceRange = createSelector(
(s) => s.uiState,
(u) => u.toJS().reference.sourceRange);

const selectActiveRef = createSelector(
(s) => s.uiState,
(u) => u.toJS().activeReference);


const mapStateToProps = (state) => {
  const { user, documentViewer } = state;
  return {
    selectedSnippet: documentViewer.uiState.get('snippet'),
    selection: selectSourceRange(documentViewer),
    doScrollToActive: documentViewer.uiState.get('goToActive'),
    doc: selectDoc(state),
    references: selectReferences(state),
    className: 'sourceDocument',
    activeReference: selectActiveRef(documentViewer),
    executeOnClickHandler: !!documentViewer.targetDoc.get('_id'),
    disableTextSelection: !user.get('_id'),
    panelIsOpen: !!documentViewer.uiState.get('panel'),
    forceSimulateSelection:
    documentViewer.uiState.get('panel') === 'targetReferencePanel' ||
    documentViewer.uiState.get('panel') === 'referencePanel'
  };
};

function mapDispatchToProps(dispatch) {
  const actions = {
    setSelection,
    unsetSelection,
    onClick: resetReferenceCreation,
    highlightReference,
    activateReference,
    scrollToActive,
    deactivateReference
  };
  return bindActionCreators(actions, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
  stateProps),
  dispatchProps),
  ownProps), {}, {
    unsetSelection: dispatchProps.unsetSelection });

}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Document);