const _excluded = ["userSelectedSorting"];function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _objectWithoutProperties(source, excluded) {if (source == null) return {};var target = _objectWithoutPropertiesLoose(source, excluded);var key, i;if (Object.getOwnPropertySymbols) {var sourceSymbolKeys = Object.getOwnPropertySymbols(source);for (i = 0; i < sourceSymbolKeys.length; i++) {key = sourceSymbolKeys[i];if (excluded.indexOf(key) >= 0) continue;if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;target[key] = source[key];}}return target;}function _objectWithoutPropertiesLoose(source, excluded) {if (source == null) return {};var target = {};var sourceKeys = Object.keys(source);var key, i;for (i = 0; i < sourceKeys.length; i++) {key = sourceKeys[i];if (excluded.indexOf(key) >= 0) continue;target[key] = source[key];}return target;}import { actions } from "../../BasicReducer";
import libraryHelpers from "./libraryFilters";
import api from "../../Search/SearchAPI";
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import rison from 'rison-node';
import { getThesaurusPropertyNames } from "../../../shared/commonTopicClassification";
import { setTableViewColumns } from "../actions/libraryActions";
import { tocGenerationUtils } from "../../ToggledFeatures/tocGeneration";
import { wrapDispatch } from "../../Multireducer";
import { UserRole } from "../../../shared/types/userSchema";
import { getTableColumns } from "./tableColumns";
import setReduxState from "./setReduxState.js";

const decodeQuery = (params) => {
  try {
    return rison.decode(params.q || '()');
  } catch (error) {
    error.status = 404;
    throw error;
  }
};

const processQuery = (params, globalResources, key) => {
  const defaultSearch = prioritySortingCriteria.get({ templates: globalResources.templates });

  let query = decodeQuery(params);

  query = Object.assign(query, {
    order: query.order || defaultSearch.order,
    sort: query.sort || defaultSearch.sort,
    view: params.view
  });

  const noDocuments = !globalResources[key] || !globalResources[key].documents.get('rows').size;

  if (noDocuments && query.limit) {
    query = Object.assign(query, { limit: query.limit + (query.from || 0), from: 0 });
  }

  const { userSelectedSorting } = query,sanitizedQuery = _objectWithoutProperties(query, _excluded);

  const loggedIn = globalResources.user && globalResources.user.has('role');
  const isAdmin = loggedIn && globalResources.user.get('role') === UserRole.ADMIN;

  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
  tocGenerationUtils.aggregations(sanitizedQuery, globalResources.settings.collection.toJS())),
  loggedIn ? { aggregatePublishingStatus: true } : {}),
  loggedIn && !isAdmin ? { aggregatePermissionsByLevel: true } : {}),
  isAdmin ? { aggregatePermissionsByUsers: true } : {});

};

const requestState = (
request,
globalResources,
options = { calculateTableColumns: false, geolocation: false }) =>
{
  const docsQuery = processQuery(request.data, globalResources, 'library');

  const documentsRequest = request.set(
  tocGenerationUtils.aggregations(docsQuery, globalResources.settings.collection.toJS()));


  const markersRequest = options.geolocation ?
  api.search(
  request.set(_objectSpread(_objectSpread({},
  docsQuery), {}, {
    geolocation: true }))) :


  { rows: [] };

  return Promise.all([api.search(documentsRequest), markersRequest]).then(
  ([documents, markers]) => {
    const templates = globalResources.templates.toJS();
    const filterState = libraryHelpers.URLQueryToState(
    documentsRequest.data,
    templates,
    globalResources.thesauris.toJS(),
    globalResources.relationTypes.toJS(),
    request.data.quickLabelThesaurus ?
    getThesaurusPropertyNames(request.data.quickLabelThesaurus, templates) :
    []);


    const state = {
      library: {
        filters: {
          documentTypes: documentsRequest.data.types || [],
          properties: filterState.properties
        },
        aggregations: documents.aggregations,
        search: filterState.search,
        documents,
        markers
      }
    };

    const addinsteadOfSet = Boolean(docsQuery.from);

    const dispatchedActions = [
    setReduxState(state, 'library', addinsteadOfSet),
    actions.set('library.sidepanel.quickLabelState', {
      thesaurus: request.data.quickLabelThesaurus,
      autoSave: false
    })];

    if (options.calculateTableColumns) {
      const tableViewColumns = getTableColumns(documents, templates, documentsRequest.data.types);
      dispatchedActions.push((dispatch) =>
      wrapDispatch(dispatch, 'library')(setTableViewColumns(tableViewColumns)));

    }
    return dispatchedActions;
  });

};

export { decodeQuery, processQuery, requestState };