function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}
import { generateID } from "../../../shared/IDGenerator";

import { intersectionBy } from 'lodash';











const prepareFormValues = (
translations,
context) =>
{
  const contextTranslations = translations.toJS().map((translation) => {var _translation$contexts;
    const currentContext = (_translation$contexts = translation.contexts) === null || _translation$contexts === void 0 ? void 0 : _translation$contexts.filter(
    (translationContext) => (translationContext === null || translationContext === void 0 ? void 0 : translationContext.id) === context);

    return _objectSpread(_objectSpread({}, translation), {}, { contexts: currentContext });
  });

  const contextTerms = Object.keys(contextTranslations[0].contexts[0].values).sort();

  const contextValues = contextTranslations.map((byLang) => {var _byLang$contexts;return _objectSpread({
      locale: byLang.locale },
    byLang === null || byLang === void 0 ? void 0 : (_byLang$contexts = byLang.contexts) === null || _byLang$contexts === void 0 ? void 0 : _byLang$contexts[0].values);});


  const formData = contextTerms.map((contextTerm) => {
    const hasUntranslatedValues =
    intersectionBy(contextValues, contextTerm).length < contextValues.length;
    return {
      key: contextTerm,
      formID: generateID(6, 6),
      hasUntranslatedValues,
      values: contextValues.map((val) => ({
        locale: val.locale,
        value: val[contextTerm]
      }))
    };
  });

  return {
    contextLabel: contextTranslations[0].contexts[0].label,
    formData,
    contextTranslations
  };
};

const prepareTranslationsToSave = (
currentTranslations,
formData) =>
{
  const preparedTranslations = currentTranslations.map((translation) => {var _translation$contexts2;
    const { locale } = translation;
    const updatedContext = translation === null || translation === void 0 ? void 0 : (_translation$contexts2 = translation.contexts) === null || _translation$contexts2 === void 0 ? void 0 : _translation$contexts2.map((context) => {
      const updatedValues = Object.keys(context.values || {}).reduce((updatedKeys, term) => {
        const valuesForKey = formData.find((data) => data.key === term);
        const updatedValue = valuesForKey === null || valuesForKey === void 0 ? void 0 : valuesForKey.values.find((value) => value.locale === locale);
        return _objectSpread(_objectSpread({},
        updatedKeys), {}, {
          [term]: updatedValue === null || updatedValue === void 0 ? void 0 : updatedValue.value });

      }, {});
      return _objectSpread(_objectSpread({}, context), {}, { values: updatedValues });
    });
    return _objectSpread(_objectSpread({}, translation), {}, { contexts: updatedContext });
  });
  return preparedTranslations;
};


export { prepareTranslationsToSave, prepareFormValues };