import api from "../../../utils/api";




const getGroups = async (requestParams) => {
  const response = await api.get('usergroups', requestParams);
  return response.json;
};
const saveGroup = async (requestParams) => {
  const response = await api.post('usergroups', requestParams);
  return response.json;
};
const deleteGroup = async (requestParams) => {
  const response = await api.delete('usergroups', requestParams);
  return response.json;
};

export { getGroups, saveGroup, deleteGroup };