function ownKeys(object, enumerableOnly) {var keys = Object.keys(object);if (Object.getOwnPropertySymbols) {var symbols = Object.getOwnPropertySymbols(object);enumerableOnly && (symbols = symbols.filter(function (sym) {return Object.getOwnPropertyDescriptor(object, sym).enumerable;})), keys.push.apply(keys, symbols);}return keys;}function _objectSpread(target) {for (var i = 1; i < arguments.length; i++) {var source = null != arguments[i] ? arguments[i] : {};i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {_defineProperty(target, key, source[key]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));});}return target;}function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}import * as types from "./actionTypes";
import libraryHelper from "../helpers/libraryFilters";
import comonPropertiesHelper from "../../../shared/comonProperties";
import * as libraryActions from "./libraryActions";
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { actions as formActions } from 'react-redux-form';

export function filterDocumentTypes(documentTypes, storeKey = 'library') {
  return (dispatch, getState) => {
    const state = getState();

    const templates = state.templates.toJS();
    const thesauris = state.thesauris.toJS();

    let libraryFilters = comonPropertiesHelper.
    comonProperties(templates, documentTypes).
    filter((prop) => prop.filter);
    libraryFilters = libraryHelper.populateOptions(libraryFilters, thesauris);

    const usefulTemplates = documentTypes.length ?
    templates.filter((t) => documentTypes.includes(t._id)) :
    templates;

    const { sort, order } = prioritySortingCriteria.get({
      currentCriteria: { sort: state[storeKey].search.sort, order: state[storeKey].search.order },
      filteredTemplates: usefulTemplates.map((t) => t._id),
      templates: state.templates,
      selectedSorting: state[storeKey].selectedSorting
    });

    const search = _objectSpread(_objectSpread({
      types: documentTypes },
    state[storeKey].search), {}, {
      sort,
      order });


    const filters = { documentTypes, properties: libraryFilters };
    dispatch(libraryActions.searchDocuments({ filters, search }, storeKey));
  };
}

export function resetFilters(storeKey) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_LIBRARY_FILTERS, documentTypes: [], libraryFilters: [] });
    dispatch(
    formActions.load(`${storeKey}.search`, {
      searchTerm: '',
      filters: {},
      order: 'desc',
      sort: 'creationDate'
    }));

    libraryActions.searchDocuments({ search: getState()[storeKey].search }, storeKey)(
    dispatch,
    getState);

  };
}

export function toggleFilter(propertyName, properties) {
  return (dispatch) => {
    const updatedProperties = properties.map((property) => {
      if (property.name === propertyName) {
        property.active = !property.active;
      }
      return property;
    });
    dispatch({ type: types.UPDATE_LIBRARY_FILTERS, libraryFilters: updatedProperties });
  };
}