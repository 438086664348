import { isSameDate } from "./isSameDate";

import { SuggestionState } from "./types/suggestionSchema";










const equalsForType = (type) => (first, second) =>
type === 'date' ? isSameDate(first, second) : first === second;

const getLabelingState = ({ currentValue, labeledValue }) => {
  if (labeledValue) {
    return 'Label';
  }

  if (currentValue) {
    return 'Value';
  }

  return 'Empty';
};

const getMatchingState = (
{ suggestedValue, currentValue },
propertyType) =>
{
  const equals = equalsForType(propertyType);

  if (suggestedValue === '') {
    return 'Empty';
  }

  if (equals(suggestedValue, currentValue)) {
    return 'Match';
  }

  return 'Mismatch';
};

const getSuggestionState = (
values,
propertyType) =>
{
  const { modelCreationDate, error, date } = values;

  if (!!error && error !== '') {
    return SuggestionState.error;
  }

  if (date < modelCreationDate) {
    return SuggestionState.obsolete;
  }

  const matchState = getMatchingState(values, propertyType);
  const labelState = getLabelingState(values);

  const state = {
    Empty: {
      Empty: SuggestionState.empty,
      Label: SuggestionState.labelEmpty,
      Value: SuggestionState.valueEmpty
    },
    Match: {
      Empty: null,
      Label: SuggestionState.labelMatch,
      Value: SuggestionState.valueMatch
    },
    Mismatch: {
      Empty: SuggestionState.emptyMismatch,
      Label: SuggestionState.labelMismatch,
      Value: SuggestionState.valueMismatch
    }
  }[matchState][labelState];

  if (state === null) throw new Error('Invalid suggestion state');

  return state;
};

export { getSuggestionState };